import { ref, watch, computed, reactive, onMounted, inject } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import { add, multiply, subtract } from '@core/utils/math/utils'

export default function outbounditemUseList(config, emit) {
  // Use toast
  const toast = useToast()

  const getList = async () => {
    if (config.id) {
      state.isBusy = true
      const res = await store.dispatch('outbounditem/list', { outbound_id: config.id })
      state.isBusy = false
      if (res['data'].code === 0) {
        state.items = res['data'].data.ext.list
        state.inbound_item = state.items[0].inbounditem_id
      } else {
        toast.error('明细列表获取错误')
      }
    }
  }

  const curKey = ref('')

  const updateReadonly = (readonly) => {
    const value = { ...filterItem.value }
    if (value) {
      value.readonly = readonly
    }
    updateEditableData(value)
  }

  const updateEditableData = (value) => {
    const obj = {}
    obj[curKey.value] = value
    state.editableData = Object.assign({}, state.editableData, obj)
  }



  const filterItem = computed(() => {
    return state.items.filter(item => item.id === curKey.value)[0]
  })

  const validateRow = async function () {
    let refs = state.observer.refs
    let validArr = []
    for (const key in refs) {
      if (refs.hasOwnProperty(key) && key.includes(curKey.value)) {
        let validate = await refs[key].validate()
        if (!validate['valid']) {
          validArr.push(key)
        }
      }
    }
    return validArr.length === 0
  }


  const methods = {
    showOrderModal() {
      state.orderModal.show()
    },
    onRowDblClicked(item) {

      const curItem = state.editableData[curKey.value]
      curItem['order_id'] = item.order_id
      curItem['order_no'] = item.order_no
      updateEditableData(curItem)
    },
    editable(key) {
      return state.editableData[key] && !state.editableData[key].readonly
    },
    onRowHovered(item) {
      curKey.value = item.id
    },
    cancel() {
      updateReadonly(true)
    },
    edit() {
      updateReadonly(false)
    },
    del() {
      this.cancel()
      filterItem.value.state = 0
    },
    async save() {
      const success = await validateRow()
      if (!success) {
        toast.error('请正确填写必填字段')
        return
      }
      filterItem.value._showDetails = false
      Object.assign(filterItem.value, state.editableData[curKey.value])
      this.cancel()
    },
    refresh() {
      state.refListTable.refresh()
    },
    onSelectStock() {
      const itemList = this.$refs.stockSelect.getChecked()
      if (itemList.length > 0) {
        for (let i = 0; i < itemList.length; i++) {
          let item = itemList[i]
          item.ext.stock_qty = item.qty
          item.ext.lockQty = item.ext.outboundTrueQtys
          item.ext.canUseQty = item.qty - item.ext.outboundTrueQtys
          const newData = {
            id: 'new' + state.items.length,
            warehouse_id:item.warehouse_id,
            warehouse_name:item.warehouse_name,
            product_id:item.product_id,
            name:item.name,
            product_unit:item.ext.productunit,
            stock_id:item.stock_id,
            batch_no:item.batch_no,
            production_date:item.production_date,
            expiry_date:item.expiry_date,
            warestatus:item.status,
            qty:0,
            state:1,
            cost:item.cost,

            ext:item.ext,
          }
          state.items.push(newData)
          curKey.value = newData.id
          this.edit()
        }
      }
    },
    showselproduct() {
      this.$bvModal.show('stockModal')
    }
  }

  const state = reactive({

    tableColumns: [
      {
        key: 'name',
        label: '名称',
        sortable: true
      },
      {
        key: 'production_date',
        label: '生产日期',
      },
      {
        key: 'product_unit',
        label: '单位',
      },
      {
        key: 'warestatus',
        label: '库存状态',
      },
      {
        key: 'warehouse_name',
        label: '仓库',
      },
      {
        key: 'stock_qty',
        label: '库存数量',
        formatter:(value, key, item)=> item.ext.stock_qty+" | 待出库："+ item.ext.lockQty
      },
      {
        key: 'qty',
        label: '出库数量',
      },
      {
        key: 'cost',
        label: '成本价',
      },
      {
        key: 'actions',
        label: '操作'
      },
    ],

    items: [],
    isBusy: false,
    refListTable: undefined,

    editableData: reactive({}),

    inbound_item:config.inbound_item_id,
    observer: undefined,
    filterItems: computed(() => state.items.filter(item => item.state === 1)),
    _disabled:false
  })

  onMounted(async () => {

    state._disabled = inject('_disabled')
    state.tableColumns = state._disabled ? state.tableColumns.filter(item =>  item.key !== 'actions')
      : state.tableColumns.filter(item => item.key !== 'trueqty' )
    await getList()
  })


  return {
    methods,
    state,
  }
}
