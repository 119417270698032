<template>
  <b-card style="width:100%">
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col v-for="(value,name,index) in page" :key="index" md="3">
          <xy-form :prop-data="value" v-model="condition[name]"></xy-form>
        </b-col>

        <b-col
            cols="12"
            md="6"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
              class="mr-1"
          >
            <span class="align-right">重置</span>
          </b-button>
          <b-button
              variant="outline-primary"
              @click="exportToExcel()"
          >
            <span class="align-right">导出</span>
          </b-button>

        </b-col>
        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' || modalName === '终端客户'  "
          />

          <user-select
              ref="creatorSelect" :department-id="2" v-else-if="'创建人' === modalName "
          />
          <warehouse-list
                  ref="warehouseSelect"  v-else-if="'仓库名称' === modalName "
          >

          </warehouse-list>

        </b-modal>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import {getCodeOptions, isEmpty} from '@core/utils/filter'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import XyForm from '@/views/components/xy/XyForm'
import outboundStore from '@/views/apps/outbound/outboundStore'

import WarehouseList from '@/views/apps/warehouse/warehouse-modal/WarehouseList'

export default {
  components: { XyForm, XyDatePicker, UserSelect, CompanySelect ,WarehouseList},
  props: {},
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('outbound')) store.registerModule('outbound', outboundStore)

    onMounted(() => {
      state.condition = store.getters['outbound/getCondition']
    })

    const state = reactive({
      condition: {
        creatorName: undefined,
      },
      companyType: '',
      modalName: '',
      singleModal:undefined,
      page:{
        outbound_no: {
          type: 'input',
          attrs: {
            id: 'outbound_no',
            label: '出库单编号',
          },
        },
        type: {
          type: 'select',
          attrs: {
            id: 'type',
            label: '出库类型',
            optionsType: 'outbound_type',
          },
        },

        product_name: {
          type: 'input',
          attrs: {
            id: 'product_name',
            label: '产品名称',
          },
        },
        creatorName: {
          type: 'input',
          attrs: {
            id: 'creatorName',
            label: '创建人',
            readonly: true,
            placeholder: '点击搜索创建人',
          },
          on: {
            click: () => methods.showSingleModal('创建人')
          }
        },
        status: {
          type: 'select',
          attrs: {
            id: 'status',
            label: '审核状态',
            optionsType: 'outbound_status',
          },
        },
        channel_name: {
          type: 'input',
          attrs: {
            id: 'channel_name',
            label: '终端客户',
            readonly: true,
            placeholder: '点击搜索终端客户',
          },
          on: {
            click: () => methods.showSingleModal('终端客户')
          }
        },
        warehouse_name: {
          type: 'input',
          attrs: {
            id: 'warehouse_name',
            label: '仓库名称',
            readonly: true,
            placeholder: '点击搜索仓库',
          },
          on: {
            click: () => methods.showSingleModal('仓库名称')
          }
        },
      },
    })
    const methods = {
      exportToExcel() {
        const condition = state.condition
        store.dispatch('outbound/exportToExcel', condition)
            .then(res => {
              toast.success("导出成功")
              let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
              let filename = "其他出库-"+new Date().getTime();
              /*-------------*/
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(data);
              link.setAttribute('download',filename+'.xlsx')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
      },
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }else if(modalName === '终端客户'){
          state.companyType = 'OffSalesChannelCustomer'
        }

        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '签约主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.company_id = data[0].company_id
              state.condition.companyName = data[0].company_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.supplier_id = data[0].company_id
              state.condition.supplierName = data[0].company_name
            }
            break
          }
          case '终端客户': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.channel_id = data[0].company_id
              state.condition.channel_name = data[0].company_name
            }
            break
          }
          case '创建人': {
            data = this.$refs.creatorSelect.getSelected()
            if (data.length > 0) {
              state.condition.creator = data[0].user_id
              state.condition.creatorName = data[0].full_name
            }
            break
          }
          case '仓库名称': {
            data = this.$refs.warehouseSelect.getSelected()
            if (data.length > 0) {
              state.condition.warehouse_id = data[0].warehouse_id
              state.condition.warehouse_name = data[0].warehouse_name
            }
            break
          }
        }
      },
      refreshTable() {
        let time = state.condition.time
        if (time && !time.includes('至')) {
          toast.error("请选择范围日期")
          return;
        }
        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {
          supplierName: undefined,
          creatorName: undefined,
          channel_name:undefined,
        }
        store.commit('outbound/updateCondition', state.condition)
        methods.refreshTable()
      },
    }
    return {
      ...toRefs(state),
      ...methods,
      //filter
      getCodeOptions
    }
  },
}
</script>

<style scoped>

</style>
