<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2" v-if="!_disabled">

        <!-- Table Top -->
        <b-row >

          <b-col
              cols="12"
              md="6"
          >
            <xy-button
                variant="primary"
                @click="showselproduct"
            >
              <span class="text-nowrap">选择商品</span>
            </xy-button>
          </b-col>
          <b-col md="3">
          </b-col>
          <!--          <b-col md="3">
                      <b-alert
                          variant="primary"
                          show
                      >
                        <div class="alert-body">
                          <span>折后金额=应付金额-现金折扣 || 实付金额=折后金额+手续费</span>
                        </div>
                      </b-alert>
                    </b-col>-->
        </b-row>

      </div>
      <ValidationObserver ref="observer">
        <b-table
            ref="refListTable"
            class="position-relative"
            :items="filterItems"
            responsive
            hover
            :fields="tableColumns"
            show-empty
            empty-text="未找到记录"
            :busy="isBusy"
            @row-hovered="onRowHovered"
        >
          <template #cell(name)="data">
            {{ data.item.name }}{{ data.item.is_gift===1?"(赠)":"" }}
            <feather-icon
                icon="TwitchIcon"
                size="21"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '69码：'+data.item.ext.productcode+
              '<br>'+'产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '</div>'"
                variant="success"
            />
          </template>

          <template #cell(product_unit)="data">
            {{getCodeLabel('product_unit', data.item.product_unit)}}
          </template>

          <template #cell(production_date)="data">
            {{toMonth(isEmpty(data.item.ext.productionDate)?data.item.production_date:data.item.ext.productionDate)}}
          </template>

          <template #cell(qty)="{field,value,item}">
            <div>
              <xy-input :vid="`${field.key}_${item.id}`" :rules="`required|nonnegativeInteger|max_value:${item.ext.canUseQty}`" :val.sync="editableData[item.id][field.key]"
                        v-if="editable(item.id)" :name="field.label"
                        @mouseout="save"
              ></xy-input>
              <div v-else>{{ value }}</div>
            </div>
          </template>

          <template #cell(warestatus)="data">
            <!--          <span :class="'text-'+getCode('stock_status', data.item.status).color">{{ getCodeLabel("stock_status", data.item.status)}}</span>-->
            <b-badge
                pill
                :variant="`light-${getCodeColor('stock_status', data.item.warestatus)}`"
            >
              {{ getCodeLabel("stock_status", data.item.warestatus)}}
            </b-badge>
          </template>


          <!-- Column: Actions -->
          <template #cell(actions)="row">
            <span v-if="editable(row.item.id)">
<!--              <xy-link @click="save" class="mr-1">保存</xy-link>-->
<!--              <xy-pop-confirm title="确认取消？" @confirm="cancel">-->
<!--                <xy-link>取消</xy-link>-->
<!--              </xy-pop-confirm>-->
            </span>
            <span v-else>
              <xy-link @click="edit" class="mr-1">编辑</xy-link>
               <xy-pop-confirm title="确认删除？" @confirm="del">
                <xy-link>删除</xy-link>
              </xy-pop-confirm>
            </span>
          </template>

        </b-table>
      </ValidationObserver>

      <b-modal
          id="stockModal"
          ok-only
          ok-title="确认"
          @ok="onSelectStock"
          cancel-title="取消"
          centered
          size="xl"
          :title="'请选择'"
      >
        <stock-list
            ref="stockSelect" :warehouseid="warehouse_id" :exclude_ids="filterItems.map(item => item.stock_id).join(',')" :purchaseTeamUserId="purchaseTeamUserId"
        >
        </stock-list>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, toRefs, computed, watch, reactive, inject } from '@vue/composition-api'
import { getCodeOptions, getCode, getCodeLabel, getCodeColor,toMonth,isEmpty} from '@core/utils/filter'
import { toTime, toDate } from '@core/utils/dayjs/utils'
import outbounditemUseList from './outbounditemUseList'
import XyInput from '@/views/components/xy/XyInput'
import XySelect from '@/views/components/xy/XySelect'
import CodeSelect from '@/views/apps/code/CodeSelect'
import XyForm from '@/views/components/xy/XyForm'
import XyPopConfirm from '@/views/components/xy/XyPopConfirm'
import XyButton from '@/views/components/xy/XyButton'
import XyLink from '@/views/components/xy/XyLink'
import StockList from '@/views/apps/stock/StockList'
import outbounditemStore from '@/views/apps/outbounditem/outbounditemStore'

export default {
  components: {
    StockList,
    XyLink,
    XyButton,
    XyPopConfirm,
    BPopover,
    XyForm,
    CodeSelect,
    XySelect,
    XyInput,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: ['id', 'warehouse_id','purchaseTeamUserId'],
  setup(props, { emit }) {
    if (!store.hasModule('outbounditem')) store.registerModule('outbounditem', outbounditemStore)

    const { id } = toRefs(props)
    const {
      methods: useListMethods,
      state: useListState
    } = outbounditemUseList({
      id: id.value,
    }, emit)

    return {
      ...toRefs(useListState),
      ...useListMethods,

      // Filter
      getCodeOptions,
      getCodeLabel,
      getCodeColor,toMonth,isEmpty,

      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

