var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(!_vm._disabled)?_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('xy-button',{attrs:{"variant":"primary"},on:{"click":_vm.showselproduct}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("选择商品")])])],1),_c('b-col',{attrs:{"md":"3"}})],1)],1):_vm._e(),_c('ValidationObserver',{ref:"observer"},[_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"items":_vm.filterItems,"responsive":"","hover":"","fields":_vm.tableColumns,"show-empty":"","empty-text":"未找到记录","busy":_vm.isBusy},on:{"row-hovered":_vm.onRowHovered},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+_vm._s(data.item.is_gift===1?"(赠)":"")+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",value:(
              '<div class=text-left>'+
              '69码：'+data.item.ext.productcode+
              '<br>'+'产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+_vm.toDate(data.item.production_date)+
              '<br>'+'结束：'+_vm.toDate(data.item.expiry_date)+
              '</div>'),expression:"\n              '<div class=text-left>'+\n              '69码：'+data.item.ext.productcode+\n              '<br>'+'产品批次：'+data.item.batch_no+\n              '<br>'+'开始：'+toDate(data.item.production_date)+\n              '<br>'+'结束：'+toDate(data.item.expiry_date)+\n              '</div>'",modifiers:{"hover":true,"html":true}}],attrs:{"icon":"TwitchIcon","size":"21","variant":"success"}})]}},{key:"cell(product_unit)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getCodeLabel('product_unit', data.item.product_unit))+" ")]}},{key:"cell(production_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.toMonth(_vm.isEmpty(data.item.ext.productionDate)?data.item.production_date:data.item.ext.productionDate))+" ")]}},{key:"cell(qty)",fn:function(ref){
              var field = ref.field;
              var value = ref.value;
              var item = ref.item;
return [_c('div',[(_vm.editable(item.id))?_c('xy-input',{attrs:{"vid":((field.key) + "_" + (item.id)),"rules":("required|nonnegativeInteger|max_value:" + (item.ext.canUseQty)),"val":_vm.editableData[item.id][field.key],"name":field.label},on:{"update:val":function($event){return _vm.$set(_vm.editableData[item.id], field.key, $event)},"mouseout":_vm.save}}):_c('div',[_vm._v(_vm._s(value))])],1)]}},{key:"cell(warestatus)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":("light-" + (_vm.getCodeColor('stock_status', data.item.warestatus)))}},[_vm._v(" "+_vm._s(_vm.getCodeLabel("stock_status", data.item.warestatus))+" ")])]}},{key:"cell(actions)",fn:function(row){return [(_vm.editable(row.item.id))?_c('span'):_c('span',[_c('xy-link',{staticClass:"mr-1",on:{"click":_vm.edit}},[_vm._v("编辑")]),_c('xy-pop-confirm',{attrs:{"title":"确认删除？"},on:{"confirm":_vm.del}},[_c('xy-link',[_vm._v("删除")])],1)],1)]}}])})],1),_c('b-modal',{attrs:{"id":"stockModal","ok-only":"","ok-title":"确认","cancel-title":"取消","centered":"","size":"xl","title":'请选择'},on:{"ok":_vm.onSelectStock}},[_c('stock-list',{ref:"stockSelect",attrs:{"warehouseid":_vm.warehouse_id,"exclude_ids":_vm.filterItems.map(function (item) { return item.stock_id; }).join(','),"purchaseTeamUserId":_vm.purchaseTeamUserId}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }